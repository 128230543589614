interface ITitleFieldProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
}
export const TitleField = ({ title, subtitle }: ITitleFieldProps) => {
  return (
    <div className="field">
      <h1 className="title is-5">{title}</h1>
      {subtitle && <h2 className="subtitle is-6">{subtitle}</h2>}
    </div>
  );
};
