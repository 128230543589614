/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Suspense, useEffect } from "react";
import {
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import { ReactQueryDevtools } from "react-query/devtools";
import ReactGA from "react-ga";
import { withView, compose } from "navi";
import HelmetProvider from "react-navi-helmet-async";
import { NotFoundBoundary, Router, View } from "react-navi";
import "./App.sass";
import pages from "./pages";

// Elements
import { Indicator } from "./components/elements/Indicator";
// Components
import { NotFoundSection } from "./components/components/NotFoundSection";
import { ErrorOccurredSection } from "./components/components/ErrorOccurredSection";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      refetchOnWindowFocus: false,
    },
  },
});

const routesWithLayout = compose(
  // Instead of rendering the latest `currentUser`, we render its value at the
  // time that the route was generated. Given that changing the user rebuilds
  // the route, it will always be up-to-date -- and doing it this way helps
  // avoid flashes of content when navigating between auth actions.
  withView((request) => {
    ReactGA.pageview(request.path);
    return (
      <Suspense fallback={<Indicator active />}>
        <NotFoundBoundary render={() => <NotFoundSection />}>
          <QueryErrorResetBoundary>
            {({ reset }) => (
              <ErrorBoundary
                onReset={reset}
                fallbackRender={({ resetErrorBoundary }) => (
                  <ErrorOccurredSection tryAgain={resetErrorBoundary} />
                )}
              >
                <View />
              </ErrorBoundary>
            )}
          </QueryErrorResetBoundary>
        </NotFoundBoundary>
      </Suspense>
    );
  }),
  pages
);

const App = () => {
  let context = {};
  useEffect(() => {
    process.env.REACT_APP_GA && ReactGA.initialize(process.env.REACT_APP_GA);
  }, []);
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <Router routes={routesWithLayout} context={context}>
            <View />
          </Router>
        </HelmetProvider>
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </div>
  );
};

export default App;
