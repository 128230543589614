import { mount } from "navi";
import FormPage from "./form";

const pages = mount({
  "/": FormPage,
  "/form": FormPage,
  "/2021": FormPage,
});

export default pages;
