import React from "react";
import classNames from "classnames";
import { mount, route } from "navi";
import { Helmet } from "react-navi-helmet-async";
// Hooks
import { useGetLineUser } from "../../../hooks/useGetLineUser";
import { useGetStatus } from "../../../hooks/useGetStatus";
// Components
import { TitleField } from "../../../components/forms/TitleField";
import { Redirect } from "../../../components/utils/Redirect";
import { Status } from "../../../domains/Status";

export default mount({
  "/": route({
    getView: () => <FormThanksPage />,
  }),
});

const FormThanksPage = () => {
  const os = liff.getOS();
  const { data: user } = useGetLineUser();
  const { data: status } = useGetStatus(user);
  if (status?.status === Status.NOT_APPLY || status?.status === Status.APPLIED) {
    window.location.href = 'https://www.announce-award.jp/';
  }
  if(status?.status === Status.CAMERA_TEST ){
    return <Redirect url="/2021/cameratest" />
  }
  if(status?.status === Status.FINALIST ){
    return <Redirect url="/2021/final" />
  }
  return (
    <div>
      <Helmet>
        <title>オンライングループ面談結果のお知らせ | 第１回学生アナウンス大賞</title>
      </Helmet>
      <div className={classNames("Page FormAppliedPage", `is-${os}`)}>
        <section className="section">
          <div className="container">
            <TitleField
              title=" 第１回学生アナウンス大賞"
              subtitle="オンライングループ面談結果のお知らせ"
            />
            <hr />
            <div className="field">
              {`${status?.entry_data.name} 様`}
            </div>
            <div className="field">
              この度は、学生アナウンス大賞オンライングループ面談にご参加いただきありがとうございました。
            </div>
            <div className="field">
              オンライングループ面談をもとに慎重に選考を重ねた結果、誠に残念ではありますが、ご期待に添えない結果となりました。申し訳ございません。<br />
              <br />
              今回初めての試みとなる「学生アナウンス大賞」には多くのご応募をいただき、大変苦慮した上での決定であることを申し添えます。<br />
              <br />
              新型コロナウイルスの影響で慣れない学生生活を送られている中、学生アナウンス大賞にエントリーし、オンライングループ面談にご参加いただきましたこと、重ねて御礼申し上げます。ありがとうございました。<br />
              <br />
              なお、選考結果に関するお問い合わせ等は一切お受けできませんので、ご了承ください。<br />
              <br />
              <br />
              末筆にはなりますが、 {`${status?.entry_data.name}様`}の今後のご活躍を心よりお祈り申し上げます。
              <br />
              <br />
              <br />
              <span className="is-pulled-right">第1回 学生アナウンス大賞実行委員会</span>
            </div>
            <hr />
            <div className="field">
              <div className="buttons is-centered">
                <a
                  href={process.env.REACT_APP_OFFICIAL_SITE_URL!}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button has-text-weight-bold is-text"
                >
                  公式サイト
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
