import { Request } from "./Request";
import { Method } from "axios";
import { LineUser } from "../domains/LineUser";
import { Status } from "../domains/Status";
import { Application } from "../domains/Application";

export interface GetStatusResponse {
  status: Status;
  application: Application;
  entry_data: {
    name: string
  }
}

export class GetStatus extends Request<GetStatusResponse> {
  method = "get" as Method;
  constructor(application: string, user: LineUser | null | undefined) {
    super();
    this.headers = {
      "X-Access-Token": `${application}`,
      "X-Line-Access-Token": user?.["line-access-token"],
    };
    this.path = `/status.json`;
    this.data = {};
  }
  parse(response: any) {
    return response.data.result as GetStatusResponse;
  }
}
