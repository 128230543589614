/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useNavigation } from "react-navi";

export const Redirect = ({ url }: { url: string }) => {
  const navigation = useNavigation();
  useEffect(() => {
    navigation.navigate(url, {
      replace: true,
    });
  }, []);
  return null;
};
