import React from "react";
import { mount, compose, withView, lazy } from "navi";
import { View } from "react-navi";
// import FormClosedPage from "./closed";
// import FormInputPage from "./input";
import MeetingThanksPage from "./meeting_thanks";

export default compose(
  withView(() => (
    <FormPage>
      <View />
    </FormPage>
  )),
  mount({
    "/": MeetingThanksPage,
    "/meeting":  lazy(() => import("./meeting_thanks")),
    "/cameratest": lazy( () => import("./cameratest")),
    "/final": lazy( () => import("./final")),
    // "/test":  lazy( () => import("./meeting")),
    // "/form": FormInputPage,
    // "/applied": lazy(() => import("./applied")),
  })
);

interface FormPageProps {
  children: React.ReactNode;
}

const FormPage = ({ children }: FormPageProps) => {
  return <div className="FormPage">{children}</div>;
};
