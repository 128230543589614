import { LineUser } from "../domains/LineUser";
import { useQuery } from "react-query";
import { GetStatus, GetStatusResponse } from "../networks/GetStatus";
// import { delay } from "../utils/delay";

export function useGetStatus(user: LineUser | null | undefined) {
  return useQuery<GetStatusResponse>(
    ["status", user?.["line-user-id"], user?.["line-access-token"]],
    async () => {
      // // TODO: mock
      // if (process.env.NODE_ENV === "development") {
      //   await delay(1000);
      //   return {
      //     status: 0,
      //     application: {
      //       id: 1,
      //       privacy_policy_url: "https://"
      //     }
      //   };
      // }
      return await new GetStatus(
        process.env.REACT_APP_APPLICATION_ID!,
        user
      ).send();
    }
  );
}
